/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { checkPermission, copyToClipboard, formatDateNotTime } from 'utils/utils';
import { getStockLedgersByFilter } from 'api/stockApi';
import { defaultpageCount, permissionKeys, sortByTypeType } from 'utils/constants';
import { useSelector } from 'react-redux';
import { locationSelector, orgSelector, userSelector } from 'redux/selectors';
import useUser from 'hooks/useUser';
import ButtonBack from 'components/button/buttonBack';
import { Table } from 'flowbite-react';
import Sort from 'components/table/sort';
import { stockLedgerTypes } from 'utils/proptypes';
import ActionTable from 'components/table/actionTable';
import SpinnerComponent from 'components/spinner';
import PaginateTable from 'components/table/paginate';

const StockLedger = (props: any) => {
  const { type } = props;
  const { WRITE_STOCK, PLATFORM_ADMIN } = permissionKeys;
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params: any = useParams();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const { locationId } = useSelector(locationSelector);
  const { fetchCurrentUser } = useUser();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(defaultpageCount);
  const [totalEntities, setTotalEntities] = useState(0);
  const [stockLedger, setStockLedger] = useState<any>(null);
  const [t] = useTranslation();
  const [sortBy, setSortBy] = useState('');
  const [sortByType, setSortByType] = useState('');
  const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_STOCK], organizationId);

  const { data, isLoading, refetch, error } = useQuery(
    ['getStockLedgers', currentPage, searchValue, sortBy, sortByType, pageCount],
    () => getStockLedgersByFilter({ page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType, stockId: params?.id }),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    if (data) {
      setStockLedger(data?.data?.entities || []);
      setTotalEntities(data.data?.totalEntities || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  const handleCopyToClipboard = (e: any, item: any, type: string) => {
    switch (type) {
      case 'amount':
        copyToClipboard(item.amount, t('stockPage.copiedAmount'));
        break;
      case 'reasonforDiscrepancy':
        copyToClipboard(item.reasonforDiscrepancy, t('stockPage.copiedReasonforDiscrepancy'));
        break;
      case 'discreprancyNotes':
        copyToClipboard(item.discreprancyNotes, t('stockPage.copiedDiscreprancyNotes'));
        break;
    case 'user':
        copyToClipboard(`${item.user?.firstName} ${item.user?.lastName}`, t('stockPage.copiedDiscreprancyNotes'));
        break;
    }
    e.stopPropagation();
  };

  const handleHeaderSort = (field: string) => {
    setSortBy(field);
    let type = '';
    if (field !== sortBy) {
      type = sortByTypeType.ASC;
    } else if (sortByType === sortByTypeType.ASC) {
      type = sortByTypeType.DESC;
    } else {
      setSortBy(type);
    }
    setSortByType(type);
  };

  return (
    <div className="flex flex-col user-detail-container w-full organization">
      {stockLedger && (
        <>
          <div className="p-[15px] cursor-pointer">
            <ButtonBack buttonName={t('back')} />
          </div>
          {/*header*/}
          <div className="user-summary w-full px-[30px] py-[15px] flex flex-col text-black">
            <p className="mr-2 text-lg">{t('stockPage.itemName')}</p>
            <p className="text-[30px] font-semibold">{stockLedger?.[0]?.stock?.item?.name}</p>
          </div>
          {/*body*/}
          <div className="w-full organization p-[30px]">
            <ActionTable
              placeholderSearch="Search"
              handleSearch={handleSearch}
            />
            <Table className="border-b">
              <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs px-3">
                  <div className="flex items-center ">
                    {t('stockPage.amount')}
                  </div>
                </Table.HeadCell>
                <Table.HeadCell
                  className="hidden-mobile bg-white font-medium text-xs"
                >
                  <div className="flex items-center">
                    {t('stockPage.reasonForDiscrepency')}
                  </div>
                </Table.HeadCell>
                <Table.HeadCell
                  className="hidden-mobile bg-white font-medium text-xs"
                >
                  <div className="flex items-center">
                    {t('stockPage.discrepencyNotes')}
                  </div>
                </Table.HeadCell>
                <Table.HeadCell className="hidden-mobile bg-white font-medium text-xs">
                  <div className="flex items-center">{t('stockPage.user')}</div>
                </Table.HeadCell>
                <Table.HeadCell
                  className="hidden-mobile w-[220px] bg-white font-medium text-xs"
                >
                  <div className="flex items-center">
                    {t('stockPage.lastReconcile')}
                  </div>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {stockLedger.map((item: stockLedgerTypes, index: number) => (
                  <Table.Row key={`row-${index + 1}`} className="cursor-pointer bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                    <Table.Cell className="py-[10px] px-3">
                      <div className="flex flex-row items-center">
                        <div className="text-black">{item?.amount}</div>
                        <div className="copy-icon pl-3 cursor-pointer text-gray-800">
                          <HiOutlineDuplicate size={25} onClick={(e: any) => handleCopyToClipboard(e, item, 'amount')} />
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="py-[10px]">
                      <div className="flex flex-row items-center">
                        <div className="text-black">{item?.reasonforDiscrepancy}</div>
                        <div className="copy-icon pl-3 cursor-pointer text-gray-800">
                          <HiOutlineDuplicate size={25} onClick={(e: any) => handleCopyToClipboard(e, item, 'reasonforDiscrepancy')} />
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="py-[10px]">
                      <div className="flex flex-row items-center">
                        <div className="text-black">{item?.discreprancyNotes}</div>
                        <div className="copy-icon pl-3 cursor-pointer text-gray-800">
                          <HiOutlineDuplicate size={25} onClick={(e: any) => handleCopyToClipboard(e, item, 'discreprancyNotes')} />
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="py-[10px]">
                      <div className="flex flex-row items-center">
                        <div className="text-black">{`${item?.user?.firstName} ${item?.user?.lastName}`}</div>
                        <div className="copy-icon pl-3 cursor-pointer text-gray-800">
                          <HiOutlineDuplicate size={25} onClick={(e: any) => handleCopyToClipboard(e, item, 'user')} />
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="py-[10px]">
                      <div className="flex flex-row items-center">
                        <div className="text-black">{formatDateNotTime(item?.modified?.toString() || '')}</div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              {isLoading && <SpinnerComponent/>}
              <PaginateTable
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalEntities={totalEntities}
                isLoadingTable={isLoading}
              />
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default StockLedger;
