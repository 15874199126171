import {orderTypes} from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import {HiOutlineDuplicate, HiOutlinePencil, HiOutlineTrash} from 'react-icons/hi';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {initOrder} from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import {defaultpageCount, permissionKeys, routePaths, sortByTypeType} from 'utils/constants';
import {toast} from 'react-toastify';
import {checkPermission, checkUseRegionAndLocation, copyToClipboard, formatDateNotTime, formatMoney, messageErrors} from '../../utils/utils';
import {useSelector} from 'react-redux';
import {locationSelector, orgSelector, userSelector} from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import {Table} from 'flowbite-react';
import Sort from 'components/table/sort';
import EmptyState from 'components/commonComponent/emptyState';
import {deletePurchaseOrderById, getPurchaseOrdersByFilter} from 'api/purchaseOrderApi';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';
import Status from "../../components/commonComponent/Status";
import {Button} from "primereact/button";
import CreatePurchaseOrderModal from 'components/modal/purchaseOrderModal/createPurchaseOrderModal';
import ActionTableWithFilter from 'components/table/actionTableWithFilter';

const OrderEmpty = require('../../assets/image/svg/shopping-cart.svg').default || '';
const locationEmty = require('../../assets/image/svg/location-marker.svg').default || '';

const PurchaseOrderRequestedList = (props: any) => {
    const queryClient = useQueryClient();
    const {WRITE_PURCHASE_ORDER, REVIEW_PURCHASE_ORDER, PLATFORM_ADMIN} = permissionKeys;
    const [t] = useTranslation();
    const {handleSetTookie} = useUser();
    const {userInfo} = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isBlank, setIsBlank] = useState(false);
    const [isLastOrder, setIsLastOrder] = useState(false);
    const [purchaseOrders, setPurchaseOrders] = useState([initOrder]);
    const [allPurchaseOrders, setAllPurchaseOrders] = useState([]);
    const [targetPurchaseOrder, setTargetPurchaseOrder] = useState(initOrder);
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const navigate = useNavigate();
    const [pageCount, setPageCount] = useState(defaultpageCount);
    const {organizationId} = useSelector(orgSelector);
    const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_PURCHASE_ORDER], organizationId);
    const isReviewPurchaseOrder = checkPermission(userInfo, props.type, [REVIEW_PURCHASE_ORDER], organizationId);
    const {locationId} = useSelector(locationSelector);
    const [location, setLocation] = useState('');
    const [region, setRegion] = useState('');
    const [fDate, setFDate] = useState('');
    const [tDate, setTDate] = useState('');
    const [statuss, setStatuss] = useState([{name: 'Requested', code: 'Requested'}]);
    const [poStatus, setPoStatus] = useState('Requested');
    const isAdmin = checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId);
    const userAccessLocationOrRegion = checkUseRegionAndLocation(userInfo, organizationId, isAdmin);


    const checkIsEmployee = (user: any) => {
        const userOrg = user.organizations?.find((x: any) => x.id === organizationId);
        let isCreateOrder: boolean = true;
        if (userOrg) {
            if (userOrg.role?.displayName === 'Employee') {
                isCreateOrder = false;
            }
        }

        return isCreateOrder;
    }

    const isEmployee = checkIsEmployee(userInfo);

    const handleCreateOrder = () => {
        navigate(`${routePaths.PURCHASE_ORDER_DETAIL}/blank-new`);
        setIsEdit(false);
    };

    const handleEdit = (id: string) => {
        navigate(`${routePaths.PURCHASE_ORDER_DETAIL}/${id}`)
    };

    const handleReview = (id: string) => {
        navigate(`${routePaths.PURCHASE_ORDER_REVIEW}/${id}`)
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await deletePurchaseOrderById(id);
            if (response.status === 200) {
                await queryClient.invalidateQueries(['getPurchaseOrders'], {refetchType: 'active'}, {cancelRefetch: true});
                const message: string = t('orderPage.deleteSuccessfully')
                toast.success(message)
            }
        } catch (error: any) {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        }
    };

    const {data, isLoading, refetch, error} = useQuery(
        ['getPurchaseOrders', currentPage, searchValue, sortBy, sortByType, pageCount],
        () => getPurchaseOrdersByFilter({
            page: currentPage,
            limit: pageCount,
            searchQuery: searchValue,
            sortBy,
            sortByType,
            organizationId,
            locationId: location,
            regionId: region,
            fDate,
            tDate,
            statuss: poStatus,
        }),
        {
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (error) {
            setPurchaseOrders([initOrder]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const getAllPurchaseOrders = useMutation(['getAllPurchaseOrders'], {
        mutationFn: getPurchaseOrdersByFilter,
        onSuccess: ({data}) => setAllPurchaseOrders(data.entities),
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    const handleCopyToClipboard = (e: any, item: any, type: string) => {
        switch (type) {
            case 'id':
                copyToClipboard(item.id, t('orderPage.copiedId'));
                break;
        }
        e.stopPropagation();
    };

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    useEffect(() => {
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, location, region, fDate, tDate, poStatus]);

    useEffect(() => {
        let poStas = '';
        for (let index = 0; index < statuss?.length; index++) {
            const status: any = statuss[index];
            if (poStas === '') {
                poStas = status.code;
            } else {
                poStas = `${poStas},${status.code}`;
            }
        }
        setPoStatus(poStas);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statuss]);

    useEffect(() => {
        if (data) {
            setPurchaseOrders(data?.data?.entities || []);
            setTotalEntities(data.data?.totalEntities || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (isBlank === true) {
            navigate(`${routePaths.PURCHASE_ORDER_DETAIL}/blank-new`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBlank]);

    useEffect(() => {
        if (isLastOrder === true) {
            getPurchaseOrdersByFilter({
                page: currentPage,
                limit: 0,
                searchQuery: searchValue,
                sortBy,
                sortByType,
                locationId
            }).then((res: any) => {
                if (res?.data?.totalEntities > 0) {
                    navigate(`${routePaths.PURCHASE_ORDER_DETAIL}/${res.data?.entities[0].id}`);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLastOrder]);

    return (
        <>
            {userAccessLocationOrRegion === true
            ?
            (
                <>
                    {error ? <EmptyState buttonName={t('stockPage.createOrder')}
                                 handleAddClick={isWriteOrg && isEmployee && handleCreateOrder}
                                 title={t('orderPage.titleNoResult')}
                                 subtitle={t('orderPage.subTitleNoResult')}
                                 icon={OrderEmpty}
                    /> :
                    <div className="main-sub">
                            <ActionTableWithFilter
                                placeholderSearch={t('orderPage.placeholderSearch')}
                                buttonName={t('stockPage.createOrder')}
                                handleAddClick={isWriteOrg && isEmployee && handleCreateOrder}
                                handleSearch={handleSearch}
                                location={location}
                                setLocation={setLocation}
                                region={region}
                                setRegion={setRegion}
                                fdate={fDate}
                                setFDate={setFDate}
                                tDate={tDate}
                                setTDate={setTDate}
                                statuss={statuss}
                                setStatuss={setStatuss}
                            />
                            <Table className="border-b">
                                <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                                    <Table.HeadCell className="cursor-pointer w-80 bg-white font-medium text-xs px-3"
                                                    onClick={() => handleHeaderSort('orderN2GId')}>
                                        <div className="flex items-center ">
                                            {t('orderPage.orderId').toUpperCase()}
                                            <Sort check={sortBy === 'orderN2GId'} sortByType={sortByType}/>
                                        </div>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="cursor-pointer w-60 bg-white font-medium text-xs"
                                                    onClick={() => handleHeaderSort('id')}>
                                        <div className="flex items-center ">
                                            {t('orderPage.region').toUpperCase()}
                                        </div>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="cursor-pointer w-60 bg-white font-medium text-xs"
                                                    onClick={() => handleHeaderSort('id')}>
                                        <div className="flex items-center ">
                                            {t('orderPage.location').toUpperCase()}
                                        </div>
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="hidden-mobile w-60 bg-white font-medium text-xs"
                                        onClick={() => {
                                            handleHeaderSort('orderedOn');
                                        }}
                                    >
                                        <div className="flex items-center">
                                            {t('orderPage.orderDate').toUpperCase()}
                                            <Sort check={sortBy === 'OrderedOn'} sortByType={sortByType}/>
                                        </div>
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="hidden-mobile w-60 bg-white font-medium text-xs"
                                        onClick={() => {
                                            handleHeaderSort('status');
                                        }}
                                    >
                                        <div className="flex items-center">
                                            {t('orderPage.status').toUpperCase()}
                                            <Sort check={sortBy === 'Status'} sortByType={sortByType}/>
                                        </div>
                                    </Table.HeadCell>
                                    <Table.HeadCell
                                        className="hidden-mobile w-60 bg-white font-medium text-xs"
                                        >
                                            <div className="flex items-center">
                                                {t('orderPage.total').toUpperCase()}
                                            </div>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="w-8 bg-white">{''}</Table.HeadCell>
                                </Table.Head>
                                <Table.Body>
                                    {purchaseOrders.map((item: orderTypes, index) => (
                                        <Table.Row key={`row-${index + 1}`}
                                                className="bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                                            <Table.Cell className="font-semibold py-[10px] px-3">
                                                <div className="flex flex-row items-center"
                                                    onClick={() => isReviewPurchaseOrder ? handleReview(item.id) : handleEdit(item.id)}>
                                                    <div className="text-secondary">{item?.id}</div>
                                                    <div
                                                        className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                        <HiOutlineDuplicate size={25}
                                                                            onClick={(e: any) => handleCopyToClipboard(e, item, 'id')}/>
                                                    </div>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell className="py-[10px]">
                                                <div className="flex flex-row items-center"
                                                    onClick={() => isReviewPurchaseOrder ? handleReview(item.id) : handleEdit(item.id)}>
                                                    <div className="text-black">{item.location?.region?.name}</div>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell className="py-[10px]">
                                                <div className="flex flex-row items-center"
                                                    onClick={() => isReviewPurchaseOrder ? handleReview(item.id) : handleEdit(item.id)}>
                                                    <div className="text-black">{item.location?.name}</div>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell className="py-[10px]">
                                                <div className="flex flex-row items-center"
                                                    onClick={() => isReviewPurchaseOrder ? handleReview(item.id) : handleEdit(item.id)}>
                                                    <div
                                                        className="text-black">{formatDateNotTime(item?.orderedOn?.toString() || '')}</div>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell className="py-[10px]">
                                                <Status type={item?.status}/>

                                            </Table.Cell>
                                            <Table.Cell className="py-[10px]">
                                                <div className="flex flex-row items-center"
                                                    onClick={() => isReviewPurchaseOrder ? handleReview(item.id) : handleEdit(item.id)}>
                                                    <div
                                                        className="text-black">{formatMoney(item.orderTotal || 0)}</div>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell className="flex items-center justify-end">
                                                {item?.status === 'Requested' && isReviewPurchaseOrder && (
                                                    <Button
                                                        className="bg-primary px-[15px] h-[34px] text-white text-sm mr-4"
                                                        type="button" onClick={() => handleReview(item.id)}>
                                                        {t('Review')}
                                                    </Button>
                                                )}
                                                {isWriteOrg && (
                                                    <GroupButtonWithIcons
                                                        className="items-center justify-center"
                                                        buttons={[
                                                            {
                                                                type: 'button',
                                                                text: '',
                                                                classType: 'white',
                                                                icon: <HiOutlinePencil size={"20px"}/>,
                                                                action: () => isReviewPurchaseOrder ? handleReview(item.id) : handleEdit(item.id),
                                                            },
                                                            {
                                                                type: 'submit',
                                                                text: '',
                                                                classType: 'white',
                                                                icon: <HiOutlineTrash size={"20px"}/>,
                                                                action: () => {
                                                                    handleDelete(item?.id)
                                                                },
                                                            },
                                                        ]}
                                                    />
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                            {isLoading && <SpinnerComponent/>}
                            <PaginateTable
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                totalEntities={totalEntities}
                                isLoadingTable={isLoading}
                            />
                    </div>}
                {openModal && (
                    <CreatePurchaseOrderModal
                        headerTitle={t('orderPage.createNewOrder')}
                        openModal={openModal}
                        isEdit={isEdit}
                        isBlank={isBlank}
                        setIsBlank={setIsBlank}
                        isLastOrder={isLastOrder}
                        setIsLastOrder={setIsLastOrder}
                        setOpenModal={setOpenModal}
                        queryClient={queryClient}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                )}
                </>
            )
            :
            (
                <div className="h-full pt-16">
                    <div className="mb-4 flex justify-center">
                        <img alt="MileMarker logo" src={locationEmty}/>
                    </div>
                    <p className="text-2xl font-semibold text-center mb-4">{t('dashboard.notificationNotLocation')}</p>
                    <p className="text-sm font-normal text-center	">{t('dashboard.noteNotLocation')}</p>
                </div>
            )
            }
        </>
    );
};

export default PurchaseOrderRequestedList;
